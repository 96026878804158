.course-report {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0;
    // background: red;
    .control button {
      color: var(--accent-color-1);
      font-size: 1.7rem;
      text-transform: uppercase;
    }
    .headline {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      position: relative;
      padding: 5px 0 5px 20px;
      h1 {
        font-size: 2.5rem;
        font-weight: 500;
      }
      h3 {
        font-size: 2rem;
        font-weight: 400;
      }
    }
  }

  .content {
    position: relative;
    // max-height: 00px;
    height: 80%;
    width: 100%;
    background: #fff;
    box-shadow: 5px 5px 20px rgba($color: #000000, $alpha: 0.16),
      -5px -5px 20px rgba($color: #000000, $alpha: 0.16);
    margin-top: 20px;
    padding: 30px;
    border-radius: 5px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--accent-color-1-light);
      border-radius: 5px;
    }
    .top-bar {
      margin: 0 0 20px;
      flex-wrap: wrap;
      .filter {
        // position: fixed;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        // height: 20px;
        z-index: 100;
        // background: var(--primary-color-1);
        user-select: none;

        .filter-item {
          display: flex;
          position: relative;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 20px;
          margin: 5px 20px 5px 0;
          font-size: 1rem;
          font-weight: 300;
          font-style: italic;
          // cursor: pointer;
          &:first-of-type {
            margin-left: 0;
          }
          .color-indicator {
            display: inline-block;
            height: 50%;
            width: 15px;
            border-radius: 5px;
            margin-right: 5px;
          }

          .indicator {
            font-size: 1.2rem;
          }
        }
      }
      .date-input-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        border: 1.6px solid var(--accent-color-1-light);
        border-radius: 5px;
        padding: 2px 0;
        input[type='month'] {
          padding: 3px 5px;
          border: none;
          border-right: 1.8px solid var(--accent-color-1-light);
          &:focus {
            outline: none;
          }
        }
        .submit {
          color: var(--accent-color-1);
          font-size: 1.2rem;
          border-radius: 0;
          text-transform: uppercase;
        }
      }
    }
  }
  .data {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .spinner {
      width: 40px;
      height: 40px;
    }
  }
}
