.card {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 280px;
  min-height: 150px;
  border-radius: 5px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.3);
  // user-select: none;
  overflow: hidden;

  .card-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--primary-color-1);
    border-radius: inherit;

    .content {
      position: relative;

      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        -o-object-fit: cover;
        -o-object-position: center;
        position: relative;
        top: 5px;
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.65);
      border-radius: inherit;
      z-index: 100;
      cursor: pointer;

      &:hover {
        h1 {
          opacity: 0.7;
          transform: translate(-50%, -65%) scale(1.1);
        }
      }

      h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 300;
        font-size: 2rem;
        width: 100%;
        color: var(--primary-color-1);
        text-align: center;
        transition: 0.2s;
        padding: 0 20px;
        text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.5);
      }
    }
  }
}
