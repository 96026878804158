.popup-menu-btn {
  position: relative;
  font-weight: 300;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  max-width: 150px;
  // height: 100%;
  margin: 0 15px;

  .three-dots {
    position: relative;
    border: 6px dotted #fff;
    border-bottom: 0;
    width: 25px;

    user-select: none;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .popup-menu {
    position: absolute;
    top: 20px;
    // right: 15px;
    width: 150px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 3px;
    animation: slide-from-top 0.2s ease-in-out;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
    z-index: 400;

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
      padding: 1.2em 1em;
      border-bottom: 1px solid rgba($color: #333, $alpha: 0.1);
      width: 100%;

      img {
        padding-right: 10px;
        flex: 0.2;
        width: 100%;
        max-width: 22px;
      }

      span {
        flex: 1;
      }

      &:last-of-type {
        border-bottom: unset;
      }

      &:hover,
      &.active {
        color: #333;
      }
    }
  }
}

@keyframes slide-from-top {
  from {
    top: 0;
    opacity: 0;
  }

  to {
    top: 20px;
    opacity: 1;
  }
}
