.doughnut-chart {
  --pi: 3.14159265359;
  --a1: 0;
  --a2: 0;
  --a3: 0;
  --r: 80;
  width: 200px;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    position: absolute;
    font-size: 1.3rem;
    font-weight: 500;
    color: #000;
    padding: 50px;
    word-break: break-word;
    text-align: center;
  }
}

svg {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes percent1 {
  to {
    stroke-dasharray: calc(2 * var(--pi) * var(--r) * var(--a1) / 100)
      calc(2 * var(--pi) * var(--r) * calc(1 - var(--a1) / 100));
  }
}

@keyframes percent2 {
  to {
    stroke-dasharray: calc(2 * var(--pi) * var(--r) * var(--a2) / 100)
      calc(2 * var(--pi) * var(--r) * calc(1 - var(--a2) / 100));
  }
}

@keyframes percent3 {
  to {
    stroke-dasharray: calc(2 * var(--pi) * var(--r) * var(--a3) / 100)
      calc(2 * var(--pi) * var(--r) * calc(1 - var(--a3) / 100));
  }
}

.donut {
  transform-origin: center center;
  transition: 0.5s ease-in-out;
  stroke-dashoffset: 0;
  cursor: pointer;
  stroke-linecap: round;
}

.donut:nth-child(1) {
  transform: rotateZ(-90deg);
  animation: percent1 1s ease-in-out forwards;
}

.donut:nth-child(2) {
  transform: rotateZ(calc(-90deg + calc(var(--a1) * 360deg / 100)));
  animation: percent2 1s ease-in-out forwards;
}

.donut:nth-child(3) {
  transform: rotateZ(
    calc(
      -90deg + calc(var(--a1) * 360deg / 100) + calc(var(--a2) * 360deg / 100)
    )
  );
  animation: percent3 1s ease-in-out forwards;
}

// .donut:nth-child(4) {
//   transform: rotateZ(
//     calc(
//       -90deg + calc(var(--a1) * 360deg / 100) + calc(var(--a2) * 360deg / 100) +
//         calc(var(--a3) * 360deg / 100)
//     )
//   );
//   animation: percent4 1s ease-in-out forwards;
// }

.donut:hover {
  stroke-width: 7;
}
