$color: #000;

.button {
  display: flex;
  position: relative;
  font-size: 1.5rem;
  padding: 1em 1.2em;
  border-radius: 50px;
  background: var(--accent-color-1);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: #fff;
  box-shadow: 3px 3px 10px rgba($color, $alpha: 0.16);
  &:hover {
    color: #efefef;
  }

  &.text-btn {
    background: none;
    box-shadow: none;
    color: var(--primary-color-1);
    padding: 0 1em;

    &:hover {
      opacity: 0.7;
    }
  }
}
