.hero-layout .img-wrapper img {
  object-position: top;
}

.home {
  position: relative;
  height: 100%;

  .landing {
    position: relative;
    height: 100%;
  }

  .landing .landing-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 1000;
    transition: 0.5s ease-in-out;

    &.active {
      transform: translate(-50%, -100%);
      .landing-header {
      }
      .search-bar {
        margin-top: 40px;
      }
    }
    .landing-header,
    .search-bar {
      position: relative;
      text-align: center;
      // transition: 0.5s ease-in-out 0.2s;
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
    }

    .landing-header {
      font-family: var(--font-family-2);

      .app-name img {
        width: 90%;
        max-width: 700px;
      }

      .app-description {
        color: var(--text-color-1);
        letter-spacing: 0.4em;
        font-size: 2rem;
        font-weight: 300;
        font-style: italic;
        // margin-top: 0.5em;
        width: 100%;
        margin: 0.5em auto 0;
      }
    }

    .search-bar {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      font-size: 1.6rem;
      margin-top: 80px;
      background: var(--accent-color-1);
      padding: 0.7em 0.3em;
      padding-right: 1em;
      border-radius: 50px;
      animation: showup 1s ease-in-out;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      .error-msg {
        position: absolute;
        font-size: 1.2rem;
        top: -50px;
        left: 10px;
        font-family: var(--font-family-1);
        color: #fff;
        background: rgb(172, 63, 63);
        padding: 1em 1.5em;
        font-weight: 500;
        border-radius: 5px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
      }
    }
  }

  .classroom-coursework {
    position: relative;
    min-height: 350px;
    // background: rgba(255, 244, 227, 0.2);
    background: rgba(194, 162, 143, 0.2);
    backdrop-filter: blur(30px) opacity(15%) brightness(15%);
    -webkit-backdrop-filter: blur(30px) opacity(15%) brightness(15%);
    // -webkit-backdrop-filter: blur(30px) opacity(60%) brightness(90%);
    // transition: 0.3s linear;
    width: 90%;
    margin: 0 auto;
    border-radius: 10px;
    opacity: 0;
    transform: translateY(-50px);
    transition: opacity 0.2s linear 0.5s, transform 0.2s linear 0.5s;
    &.active {
      top: var(--yPOS);
      opacity: 1;
      transform: translateY(0px);
    }

    .classroom-coursework-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 20px 30px;
      height: 100%;
      .top-bar {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        user-select: unset;
        width: 100%;
        margin: 10px 0 20px;

        .classroom {
          font-size: 2.2rem;
          font-weight: 500;
          padding: 0.8em;
          color: #fff;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          user-select: unset;
          flex-grow: 1;

          img {
            position: relative;
            margin-left: 10px;
            width: 15px;
            object-position: center;
            object-fit: contain;
            cursor: pointer;
            user-select: none;

            &:hover {
              opacity: 0.7;
            }
          }
        }

        .popup-menu-btn-wrapper {
          position: absolute;
          right: 0;
        }
      }
      .courseworks {
        position: relative;
        width: calc(100% + 80px);
        display: inline-grid;
        height: 100%;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        // grid-template-rows: repeat(auto-fit, 110px);
        gap: 30px 50px;
      }
    }
  }
}

@keyframes showup {
  from {
    bottom: 10px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

@media only screen and (max-width: 650px) {
  .home .landing .landing-wrapper .landing-header .app-name img {
    width: 98%;
  }
  .home .landing .landing-wrapper .landing-header .app-description {
    font-size: 1.7rem;
  }
}

@media only screen and (max-width: 630px) {
  .home .landing .landing-wrapper .landing-header .app-description {
    font-size: 1.5rem;
  }
  .home .landing .landing-wrapper .search-bar {
    flex-direction: column;
    align-items: flex-end;
    background: none;
    box-shadow: none;

    .autocomplete {
      margin: 0;
      // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    }

    .button {
      margin: 0;
    }
    .button.text-btn {
      background: var(--accent-color-1);
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
      padding: 0.5em 1em;
      margin-top: 1em;
      &:hover {
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }
}
