.header {
  position: fixed;
  height: 60px;
  width: 100%;
  // background: blue;
  z-index: 2000;
  overflow: hidden;
  padding: 0 var(--main-padding-lr);

  .wrapper {
    height: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: 250px;
      cursor: pointer;
    }
    ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      user-select: none;
      outline: none;
      list-style: none;
      li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 1rem;
        margin-left: 1.5em;
        img {
          width: 15px;
          // margin-left: 10px;
        }
        .user-icon-wrapper {
          width: 30px;
          height: 30px;
          // padding: 5px;
          border-radius: 50%;
          overflow: hidden;
          img {
            position: relative;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .header .wrapper {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 400px) {
  .header .wrapper {
    justify-content: space-between;
  }
  .header .wrapper .logo {
    // display: none;
    width: 200px;
  }
}

@media only screen and (max-width: 320px) {
  .header .wrapper .logo {
    // display: none;
    width: 160px;
  }
}
