.select-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  .selected-item {
    position: relative;
    font-size: 2.5rem;
    font-weight: 500;
    padding: 0.8em;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    user-select: unset;
    flex-grow: 1;
    cursor: pointer;
    user-select: none;
    min-width: 300px;
    transition: 0.2s;
    .spinner {
      width: 20px;
      height: 20px;
    }
    img {
      position: relative;
      margin-left: 10px;
      width: 15px;
      object-position: center;
      object-fit: contain;
      cursor: pointer;
      user-select: none;
      transition: 0.2s;

      &.active {
        transform: rotate(180deg);
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .select-options {
    position: absolute;
    font-size: 1.4rem;
    top: 60px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 5px;
    background: #fff;
    width: 100%;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    max-height: 200px;
    transition: 0.2s;
    animation: show-up 0.3s;
    z-index: 500;

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c5c5c5;
      border-radius: inherit;

      &:hover {
        background: #afafaf;
      }
    }

    span {
      display: inline-block;
      padding: 1.2em 1.2em;
      width: 100%;
      border-bottom: 1px solid #33333315;
      cursor: pointer;

      &:first-of-type {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      &:last-of-type {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom: unset;
      }

      &:hover,
      &.active {
        background: #efefef;
      }
    }
  }
}

@keyframes show-up {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
