.snackbar {
  display: flex;
  position: fixed;
  top: -100px;
  left: 50%;
  min-height: 40px;
  background: #cddfe6;
  min-width: 300px;
  margin: 0 auto;
  transform: translateX(-50%);
  color: #333;
  justify-content: center;
  align-items: center;
  padding: 0.7em 1em;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-size: 2.3rem;
  font-weight: 600;
  user-select: none;
  z-index: 3000;
  transition: 0.3s ease-in-out;
  &.active {
    display: flex;
    top: 13px;
  }
  h6 {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    // color: #06697b;
    span {
      flex: 1;
      margin-left: 8px;
      word-wrap: break-word;
    }
    img:first-of-type {
      width: 22px;
      margin-left: 0;
      justify-self: flex-start;
      cursor: unset;
    }
    img {
      width: 12px;
      margin-left: 20px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .snackbar {
    font-size: 1.8rem;
  }
}
