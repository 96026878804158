.spinner {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid rgba(51, 51, 51, 0.2);
  border-top-color: #333;
  animation: rotate 0.8s linear infinite;
  margin: 10px;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
