.checkbox {
  position: relative;
  margin-bottom: 3px;
  font-size: inherit;
  color: #333;
  width: 100%;
  font-weight: 500;
  line-height: 1;

  .checkbox-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.8em;
    padding-left: 2.8em;
    font-size: 1.4rem;
    text-align: left;

    &:hover {
      input[type='checkbox']:checked + .checkmark {
        background: none;
        opacity: unset;
      }

      .checkmark {
        background: var(--accent-color-1-light);
        opacity: 0.5;
      }
    }

    input[type='checkbox'] {
      position: absolute;
      opacity: 0;

      &:checked + .checkmark::before {
        left: 0;
      }
    }

    .checkmark {
      position: absolute;
      left: 8px;
      top: 50%;
      height: 20px;
      width: 20px;
      border: 1px solid var(--accent-color-1-light);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      transform: translateY(-50%);
      border-radius: 3px;
      -webkit-transition: 0.1s;
      -moz-transition: 0.1s;
      transition: 0.1s;
      overflow: hidden;
      text-align: center;

      &::before {
        content: '';
        position: relative;
        display: inline-block;
        top: 2px;
        width: 10px;
        height: 10px;
        background: var(--accent-color-1-light);
        border-radius: 2px;
        -webkit-transition: 0.2s ease-in-out;
        -moz-transition: 0.2s ease-in-out;
        transition: 0.2s ease-in-out;
        left: -100%;
      }
    }
  }
}
