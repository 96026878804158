:root {
  --max-width: 1920px;
  --font-family-1: 'Montserrat', sans-serif;
  --font-family-2: 'Nunito', sans-serif;
  --main-padding-lr: 50px;
  --primary-color-1: #fff4e3;
  --accent-color-1: #b17373;
  --accent-color-1-light: #ba8e8e;
  --text-color-1: #fff4e3;
  --text-color-2: #8d8d8d;
  --letter-spacing: 0.06em;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: var(--letter-spacing);
}
html {
  font-size: 10px;
  line-height: 1.5;
}

html,
body,
#root {
  height: 100%;
  // overflow: auto;
}
body {
  font-family: var(--font-family-1);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--primary-color-1);
}

// Layout
.hero-layout {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .main {
    position: relative;
    height: 100%;
    flex: 1;

    .img-wrapper {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.3);
        z-index: -999;
      }
    }
    .img-wrapper img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      -o-object-fit: cover;
      -o-object-position: center;
      z-index: -1000;
    }
    .children-wrapper {
      position: relative;
      padding: 0 var(--main-padding-lr);
      max-width: var(--max-width);
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
  }
}

.side-bar-layout {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .children-wrapper {
    position: relative;
    padding: 0 var(--main-padding-lr);
    // side bar width
    padding-left: 300px;
    max-width: var(--max-width);
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  .side-bar-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 400;
    padding: 0 0 1.3em;
    
    cursor: pointer;
    user-select: none;
    &:first-of-type {
      // for testing
      font-weight: 700;
      font-style: italic;
    }
    img {
      width: 13px;
      margin-right: 15px;
    }
  }
}
button {
  font-family: var(--font-family-1);
  font-weight: 500;
  border: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  outline: none;
  cursor: pointer;
  letter-spacing: var(--letter-spacing);
}
input {
  font-family: var(--font-family-1);
}
input[type='text'] {
  outline: none;
  outline-style: none;
  padding: 0.7em 1em;
  border-radius: 5px;
  border: none;
  font-size: 1.6rem;
  width: 200px;
  color: #333;

  &::placeholder {
    font-size: 1.4rem;
    text-align: center;
    font-style: italic;
    color: var(--text-color-2);
    letter-spacing: var(--letter-spacing);
  }
}

@media only screen and (max-width: 800px) {
  :root {
    --main-padding-lr: 10px;
  }
}
