.side-bar {
  position: fixed;
  height: 100%;
  width: 250px;
  background: rgba(58, 52, 63, 0.83);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: 5px 0 20px rgba(0, 0, 0, 0.16);
  color: #fff;
  overflow-y: scroll;
  padding: 15px;
  z-index: 1000;
  &::-webkit-scrollbar {
    width: 0;
  }
  .side-bar-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;

    .profile-img {
      position: relative;
      height: 80px;
      width: 80px;
      margin: 20px auto 30px;
      border-radius: 9999px;
      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 9999px;
      }
    }
  }
}
