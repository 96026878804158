.avatar {
  position: relative;
  font-size: 1.3rem;
  margin: 15px;
  width: 120px;
  text-align: center;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  &.display {
    .avatar-details {
      display: flex;
    }
  }
  &.active {
    justify-content: flex-start;
    align-items: flex-start;
    background: #efefef;
    width: 350px;
    min-height: 190px;
    border-radius: 5px;
    border-top-left-radius: 150px;
    padding: 1em;
    z-index: 500;
    overflow-wrap: break-word;
    text-align: left;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    .avatar-details {
      visibility: visible;
      opacity: 1;
    }
    .avatar-wrapper {
      width: 120px;
      align-items: flex-start;
      text-align: left;
    }
  }
  &:hover {
    .avatar-wrapper {
      .avatar-icon-wrapper::before {
        animation: stretch 0.8s ease-in-out infinite alternate;
        animation-delay: 0.1s;
      }

      .avatar-icon {
        animation: float 0.8s ease-in-out infinite alternate;
      }
    }
  }
  .avatar-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    text-align: center;

    .avatar-icon-wrapper {
      position: relative;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin-bottom: 28px;

      &::before {
        content: '';
        position: absolute;
        width: 90%;
        height: 8px;
        bottom: -18px;
        left: 50%;
        background: rgba(51, 51, 51, 0.15);
        filter: blur(2px);
        -webkit-filter: blur(2px);
        border-radius: 50%;
        transform: translateX(-50%);
        // animation: stretch 0.8s ease-in-out infinite alternate 0.1s;
        // animation-delay: 0.1s;
      }
    }
    .avatar-icon {
      position: relative;
      width: 70px;
      height: 70px;
      border: 3px solid #e57575;
      border-radius: 50%;
      margin-bottom: 20px;
      background: #fff;
      // animation: float 0.8s ease-in-out infinite alternate;

      img {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }
    .avatar-content-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
  .avatar-details {
    visibility: hidden;
    display: none;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 0.5em;
    font-size: inherit;
    margin-left: 1em;
    flex: 1 0 0;
    max-height: 200px;
    overflow-y: scroll;
    opacity: 0;
    -webkit-transition: 0.2s ease-in-out 0.3s;
    -moz-transition: 0.2s ease-in-out 0.3s;
    transition: 0.2s ease-in-out 0.3s;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}

@keyframes float {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(5px);
  }
}
@keyframes stretch {
  from {
    width: 90%;
    height: 8px;
    background: rgba(51, 51, 51, 0.15);
  }
  to {
    width: 60%;
    height: 4px;
    background: rgba(51, 51, 51, 0.18);
  }
}

@media only screen and (max-width: 490px) {
  .avatar.active {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 100%;

    .avatar-wrapper,
    .avatar-content-wrapper {
      justify-content: center;
      align-items: center;
    }
  }
}
