.item-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.2em;
  width: 100%;
  h3 {
    display: flex;
    font-size: inherit;
    font-weight: 500;
    margin-bottom: 0.3em;
    img {
      width: 11px;
      margin-right: 5px;
    }
  }
  span {
    font-size: 1.1rem;
    font-weight: 300;
    font-style: italic;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    padding: 0.7em 0;
    &:first-of-type {
      padding-top: 0;
    }
    &:last-of-type {
      border-bottom: none;
    }
  }
}
