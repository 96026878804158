.autocomplete {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  font-weight: 500;
  margin: 0 10px;
  user-select: none;
  animation: appear 0.5s ease-in-out;

  .search-field {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 30px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    font-size: 1.6rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);

    input[type="text"] {
      background: none;
    }

    .selected-options {
      position: relative;
      font-size: 1.2rem;
      padding: 0 0.8em;
      background-color: var(--accent-color-1-light);
      align-self: stretch;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: #fff;
      width: 35px;
      height: 35px;
      left: 2px;
      top: 3px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
      }
    }

    .icon {
      position: relative;
      margin-right: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transition: 0.2s;
      cursor: pointer;
      user-select: none;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      &.cross img {
        width: 11px;
        color: red;
      }
      img {
        width: 13px;
        object-position: center;
        object-fit: contain;
      }

      &:hover {
        img {
          opacity: 0.7;
        }
      }
    }
  }

  .search-options {
    position: absolute;
    top: 45px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #efefef;
    border-radius: inherit;
    margin-top: 10px;
    font-size: 1.3rem;
    padding-top: 3px;
    opacity: 0;
    visibility: hidden;
    transition: 0.1s;
    max-height: 200px;
    overflow-y: scroll;
    z-index: 100;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c5c5c5;
      border-radius: inherit;

      &:hover {
        background: #afafaf;
      }
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  &.active {
    .icon {
      transform: rotate(180deg);
    }
    .icon.cross {
      transform: unset;
    }
  }
}

@keyframes appear {
  from {
    transform: translateX(-18px);
    // opacity: 0;
  }
  to {
    transform: translateX(0px);
    // opacity: 1;
  }
}
