.login {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .button {
    display: flex;
    img {
      width: 23px;
      margin-right: 10px;
    }
  }
}