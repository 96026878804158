$on-time-color: #75e597;
$late-color: #e5b875;
$not-submitted-color: #e57575;
.coursework-card {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 2000;
  margin-left: -50vw;

  .coursework-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    background: var(--primary-color-1);
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    max-height: 900px;
    max-width: var(--max-width);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
    .top-bar {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0 20px;
      width: 100%;
      .back-btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        // position: absolute;
        min-width: 50px;
        left: 0;
        top: 0;
        cursor: pointer;
        user-select: none;

        img {
          position: relative;
          width: 40%;
        }
        &:hover {
          opacity: 0.7;
        }
      }
      .coursework-headline {
        flex-grow: 1;
        margin-left: 2em;
        h1 {
          font-size: 2.5rem;
          font-weight: 600;
        }
        h6 {
          position: absolute;
          font-weight: 400;
          font-size: 1.2rem;
        }
      }
      .popup-menu-btn-wrapper {
        // position: absolute;
        right: 0;
        span {
          font-weight: 500;
        }
        .popup-menu-btn .three-dots {
          border-color: var(--accent-color-1);
        }
      }
    }
    .coursework-content {
      position: relative;
      width: 100%;
      max-height: 100%;
      padding: 10px 50px 20px;
      overflow-y: scroll;
      z-index: -1;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--accent-color-1-light);
        border-radius: 5px;
      }

      .filter {
        // position: fixed;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        // height: 20px;
        z-index: 100;
        background: var(--primary-color-1);
        user-select: none;

        .filter-item {
          display: flex;
          position: relative;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 20px;
          margin: 5px 20px 5px 0;
          font-size: 1rem;
          font-weight: 300;
          font-style: italic;
          cursor: pointer;
          &:first-of-type {
            margin-left: 0;
            // .color-indicator {
            //   background: $not-submitted-color;
            // }
          }
          // &:nth-of-type(2) .color-indicator {
          //   background: $late-color;
          // }
          // &:nth-of-type(3) .color-indicator {
          //   background: $on-time-color;
          // }

          .color-indicator {
            display: inline-block;
            height: 50%;
            width: 15px;
            border-radius: 5px;
            margin-right: 5px;
          }

          .indicator {
            font-size: 1.2rem;
          }
        }
      }

      .coursework-submission-status {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 10px;
        flex-wrap: wrap;
        border-bottom: 1px solid rgba($color: #333, $alpha: 0.1);

        &:last-of-type {
          border-bottom: unset;
        }
        h2 {
          font-size: 1.6rem;
          font-weight: 500;
          font-style: italic;
          margin: 0.8em 0 0.5em;
        }
        .items {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;

          span.info {
            font-weight: 300;
            font-style: italic;
            text-transform: uppercase;
            font-size: 1.2rem;
            color: #333;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .coursework-card .coursework-card-wrapper .top-bar .coursework-headline h1 {
    font-size: 2rem;
  }
  .coursework-card .coursework-card-wrapper {
    width: 95%;
  }
}

@media only screen and (max-width: 900px) {
  .coursework-card .coursework-card-wrapper .top-bar .coursework-headline h1 {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 450px) {
  .home .classroom-coursework {
    width: 95%;
    .select-menu .selected-item {
      font-size: 2rem;
    }
  }
  .coursework-card
    .coursework-card-wrapper
    .coursework-content
    .coursework-submission-status
    .items {
    flex-direction: column;
  }
  .home .classroom-coursework .classroom-coursework-wrapper {
    padding-left: 28px;
    padding-right: 28px;
  }
}
